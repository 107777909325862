.reservation ol > li {
  list-style-type: disc;
  margin-bottom: 15px;
}
.reservation .reservation-resume {
  padding: 30px;
}
.reservation .reservation-resume strong {
  font-weight: 600;
}
.reservation .reservation-resume .panel-title {
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
.reservation .reservation-resume {
    padding: 15px 30px;
}
}
.reservation .reservation-resume-message {
  width: 100%;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
.reservation .reservation-resume-message {
    margin-bottom: 0;
    margin-top: 1rem;
}
.reservation .reservation-resume-message .checkout-message {
    text-align: left;
}
}
.reservation .reservationRefundable {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
.reservation .reservation-resume-left {
  margin-top: 1rem;
  padding-bottom: 1rem;
}
.reservation .reservation-resume-right {
  margin-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 992px) {
.reservation .reservation-resume-left {
    margin-top: 1rem;
    padding-top: 30px;
    padding-bottom: 30px;
}
.reservation .reservation-resume-right {
    margin-top: 1rem;
    padding-top: 30px;
    padding-bottom: 30px;
}
}
.reservation .confirm-subtitle {
  font-size: 19px;
}
.reservation .checkout-message {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1.5rem;
}
.reservation .confirm-message {
  font-size: 16px;
  margin-bottom: 2rem;
}
.reservation .confirm-message .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 2rem;
}
.reservation .confirm-message .buttons a {
  margin: 1rem;
}
.reservation .confirm-message-heading {
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 3rem;
  margin-top: 2rem;
  font-weight: normal;
  color: #333333;
}
.reservation .confirm-resume {
  border: 3px solid #FF9F00;
  margin-bottom: 3rem;
  padding: 0.75rem 1rem;
}
.reservation .confirm-resume b {
  font-weight: 600;
}
.reservation .checkin-checkout-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.reservation .checkin-checkout-box > div {
  -webkit-box-flex: 50%;
      -ms-flex: 50%;
          flex: 50%;
  width: 50%;
  padding-right: 1rem;
}
.reservation .reservation-resume svg {
  margin-right: 6px;
}
.reservation .reservation-resume svg > * {
  fill: #333333;
}
.reservation .terms-link {
  color: #0077CA;
  cursor: pointer;
}
@media (min-width: 992px) {
.reservation .reservation-resume {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.reservation .reservation-resume-left {
    width: 50%;
    padding-right: 60px;
    border-right: 1px solid #F2F2F2;
}
.reservation .reservation-resume-right {
    width: calc(50% - 60px);
    margin-left: 60px;
}
}
@media (min-width: 1200px) {
.reservation .reservation-resume {
    padding: 15px 60px 30px 60px;
}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
.reservation .reservation-resume {
    padding: 0;
}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
.reservation .reservation-resume {
    padding: 0;
}
}
@media (max-width: 991.98px) {
.reservation {
    margin-bottom: 1rem;
}
.reservation .reservation-resume-left {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.reservation #toggle-info-left {
    margin-top: 3rem;
}
}
.reservation .reservationIntro {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 14px;
  font-weight: 500;
}
.reservation .toggleReservation {
  text-align: center;
}
.reservation .toggleReservation .info {
  margin-bottom: 1rem;
  padding-top: 0;
}
.reservation .toggleReservation .name {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 1rem;
}
.reservation .toggleReservation .policyCancellazione {
  font-size: 14px;
  margin-top: 1rem;
}
.reservation .toggleReservation .toggler {
  font-weight: 500;
}
.reservation .toggleReservation .toggler svg {
  width: 26px;
  margin-left: 4px;
}
@media (min-width: 992px) {
.reservation #toggle-info-left,
.reservation #toggle-info-right {
    display: block !important;
}
.reservation .toggleReservation {
    display: none;
}
}